import { IonContent, IonPage, IonSpinner } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import AirtableCalendarIframe from '@facilities/components/AirtableCalendarIframe';
import Header from '@facilities/components/Header/Header';
import TasksAlert from '@facilities/components/TasksAlert/TasksAlert';
import AirtableCalendarMode from '@facilities/constants/calendarConstants';
import useGetFacility from '@facilities/hooks/useGetFacility';
import containersStyles from '@facilities/views/containers.module.css';
import pageStyles from '@facilities/views/Home/Home.module.css';

const Home = () => {
  const { t } = useTranslation('app', { keyPrefix: 'facilities' });

  const { facilityId } = useParams<{ facilityId: string }>();

  const { data: facility, isSuccess, isLoading } = useGetFacility(facilityId);

  const baseCalendarUrl = facility?.calendarUrl;
  const weeklyCalendarUrl = baseCalendarUrl
    ? `${baseCalendarUrl}?mode=${AirtableCalendarMode['week ']}`
    : null;

  const planningTitle = t('home.planning.title');

  const getContent = () => {
    return (
      <IonContent className="ion-padding">
        <div className={containersStyles.fullWidthFlexContainer}>
          <TasksAlert />
          <h2 className={`ion-padding ${pageStyles.planningTitle}`}>{planningTitle}</h2>
          <div
            className={`${containersStyles.fullHeightFlexContainer} ${pageStyles.planningContainer}`}
          >
            {isSuccess && !!weeklyCalendarUrl ? (
              <AirtableCalendarIframe calendarUrl={weeklyCalendarUrl} title={planningTitle} />
            ) : (
              <p className="ion-padding">{t('home.planning.errorMessage')}</p>
            )}
          </div>
        </div>
      </IonContent>
    );
  };

  return (
    <IonPage>
      <Header title={t('common.menu.home')} facility={facility} />
      {isLoading && <IonSpinner />}
      {isSuccess && getContent()}
    </IonPage>
  );
};

export default Home;
