import { IonContent, IonPage, IonSpinner } from '@ionic/react';
import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation, useParams } from 'react-router';

import Header from '@facilities/components/Header/Header';
import TasksAlert from '@facilities/components/TasksAlert/TasksAlert';
import { REQUEST_CREATION_FORM_WIDGET_URL } from '@facilities/constants/retoolConstants';
import useGetFacility from '@facilities/hooks/useGetFacility';
import { ANALYTICS_LOG_EVENTS } from '@facilities/utils/Analytics';
import formatRetoolWidgetUrl from '@facilities/utils/utils-fn';
import containersStyles from '@facilities/views/containers.module.css';
import RetoolWidget from '@facilities/views/RetoolWidget/RetoolWidget';
import { logEvent } from '@shared/utils/Analytics';

type NewRequestHandleDataType = { createdRequestId?: string; shouldRedirectToRequests?: boolean };

type LocationProps = {
  wasRedirectedFromCarersPage?: boolean;
};

const NewRequest = () => {
  const { t } = useTranslation('app', { keyPrefix: 'facilities' });
  const history = useHistory();
  const location = useLocation<LocationProps>();

  const { facilityId } = useParams<{ facilityId: string }>();
  const { data: facility, isSuccess, isLoading } = useGetFacility(facilityId);

  const url = formatRetoolWidgetUrl(REQUEST_CREATION_FORM_WIDGET_URL, { facilityId });

  const wasRedirectedFromCarersPageRef = useRef<boolean>(false);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    wasRedirectedFromCarersPageRef.current = !!location.state?.wasRedirectedFromCarersPage;
  }, [location.state]);

  const redirect = ({ createdRequestId, shouldRedirectToRequests }: NewRequestHandleDataType) => {
    if (!createdRequestId && !shouldRedirectToRequests) {
      return;
    }

    const currentPath = history.location.pathname;
    const requestsPath = !!createdRequestId ? `requests/${createdRequestId}` : `requests`;
    const newPath = currentPath.replace(/new-request.*/, requestsPath);

    const wasRedirectedFromCarersPage = wasRedirectedFromCarersPageRef.current;

    if (wasRedirectedFromCarersPage) {
      void logEvent(ANALYTICS_LOG_EVENTS.NEW_REQUEST_CREATED_AFTER_REDIRECT_FROM_CARERS_PAGE);
    }

    history.push(newPath, { shouldReload: true });
  };

  return (
    <IonPage>
      <Header title={t('common.menu.newRequest')} facility={facility} />
      {isLoading && <IonSpinner />}
      {isSuccess && (
        <IonContent className="ion-padding">
          <div className={containersStyles.fullWidthFlexContainer}>
            <TasksAlert />
            <div className={containersStyles.fullHeightFlexContainer}>
              <RetoolWidget url={url} handleData={redirect} />
            </div>
          </div>
        </IonContent>
      )}
    </IonPage>
  );
};

export default NewRequest;
